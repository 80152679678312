<!--
 * @Author: your name
 * @Date: 2021-07-27 14:37:28
 * @LastEditTime: 2021-09-23 11:29:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \main-site\src\views\Home.vue
-->
<template>
  <div>
    <v-img
      src="../assets/home_scenario.jpg"
      gradient="to right, rgba(1,1,1,.9), rgba(1,1,1,.4), rgba(1,1,1,.0)"
      height="380px"
    >
      <div
        style="position: absolute; top: 25%; left: 15%; width: 50%; height: 80%;"
      >
        <h3
          style="font-size: 28px;color: #fff;line-height: 80px;font-weight: 500;"
        >
          应用场景
        </h3>
        <p style="color: #fff; line-height: 36px;font-size: 16px;">
          高质量的AI图文识别模型，赋能各种场景
          <br />助力行业智能升级，打造智能解决方案标杆
        </p>
        <br />
        <v-btn
          outlined
          color="#f7a300"
          width="158px"
          height="44px"
          style="font-size:20px;"
          @click="$router.push('/contact')" href="javascript:;"
          >获取方案</v-btn
        >
      </div>
    </v-img>
    <br><br><br>
    <v-container>
      <!-- 第一层 -->
      <v-row>
        <v-col>
          <h2 style="font-weight: normal;background: #f8f8f8;">
            <img class="pt-4" height="40px" width="30px" src="../assets/scenario_icon_doc.png">
            档案/资料电子化
          </h2>
          <p style="color: #999;font-size: 16px;text-align: left; line-height: 20px;">
            文字识别是档案/资料等管理系统的数字化加工端的核心功能，精准识别档案/资料中的各类文字信息，并对信息进行语义分析、结构化处理，提高资料文档信息录入及检索效率，使得系统更具竞赛力。
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="pt-0">
          <v-img class="grey lighten-1" contain height="254px" src="../assets/scenario_doc1.jpg"></v-img>
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-img class="grey lighten-1" contain height="254px" src="../assets/scenario_doc2.jpg"></v-img>
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-img class="grey lighten-1" contain height="254px" src="../assets/scenario_doc3.png"></v-img>
        </v-col>
      </v-row>
      <br><br><br>

      <v-row>
        <v-col>
          <h2 style="font-weight: normal;background: #f8f8f8;">
            <img class="pt-4" height="40px" width="30px" src="../assets/scenario_icon_card.png">
            卡证文字识别
          </h2>
          <p style="color: #999;font-size: 16px;text-align: left; line-height: 20px;">
            卡证文字识别提供身份证识别、银行卡识别、名片识别、营业执照识别等多种服务，支持将图片上的文字内容，智能识别为结构化的文本，可应用于用户注册、银行开户、交通出行、政务办事等多种场景，大幅提升信息处理效率，还可定制个性化卡证识别模型。
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="pt-0">
          <v-img class="grey lighten-1" contain height="254px" src="../assets/scenario_card1.jpg"></v-img>
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-img class="grey lighten-1" contain height="254px" src="../assets/scenario_card2.jpg"></v-img>
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-img class="grey lighten-1" contain height="254px" src="../assets/scenario_card3.jpg"></v-img>
        </v-col>
      </v-row>
      <br><br><br>

      <v-row>
        <v-col>
          <h2 style="font-weight: normal;background: #f8f8f8;">
            <img class="pt-4" height="40px" width="30px" src="../assets/scenario_icon_bill.png">
            票据文字识别
          </h2>
          <p style="color: #999;font-size: 16px;text-align: left; line-height: 20px;">
            对财税报销、税务核算等场景所涉及的各类票据进行结识别，包括银行回单、增值税发票、购物小票、定额、机打、火车票、出租车票、汽车票、飞机行程单、过路过桥费发票、船票、银行汇票、支票常见票据，还可定制个性化票据识别模型。
          </p>
          <!--  人体步态识别、体感游戏、AI 美体、虚拟现实、增强现实、康复训练、体育教学  -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="pt-0">
          <v-img class="grey lighten-1" contain height="254px" src="../assets/scenario_bill1.jpg"></v-img>
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-img class="grey lighten-1" contain height="254px" src="../assets/scenario_bill2.jpg"></v-img>
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-img class="grey lighten-1" contain height="254px" src="../assets/scenario_bill3.jpg"></v-img>
        </v-col>
      </v-row>
      <br><br><br>

      <v-row>
        <v-col>
          <h2 style="font-weight: normal;background: #f8f8f8;">
            <img class="pt-4" height="40px" width="30px" src="../assets/scenario_icon_edu.png">
            教育场景文字识别
          </h2>
          <p style="color: #999;font-size: 16px;text-align: left; line-height: 20px;">
            识别教育场景所涉及的作业及试卷中公式、手写文字、题目等内容，可广泛适用于智学生作业、考卷的自动化录入及教学数据的自动分析、书摘笔记电子化等业务场景，存进管理的数字化和智能化。
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="pt-0">
          <v-img class="grey lighten-1" contain height="254px" src="../assets/scenario_edu1.jpg"></v-img>
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-img class="grey lighten-1" contain height="254px" src="../assets/scenario_edu2.jpg"></v-img>
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-img class="grey lighten-1" contain height="254px" src="../assets/scenario_edu3.jpg"></v-img>
        </v-col>
      </v-row>
      <br><br>
      
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Scenario",
};
</script>