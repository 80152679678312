<!--
 * @Author: your name
 * @Date: 2021-07-27 14:35:39
 * @LastEditTime: 2021-09-29 16:42:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \main-site\src\App.vue
-->
<template>
  <v-app>
    <!--  菜单appbar  -->
    <v-app-bar app color="white">
      <v-container class="white lighten-5">
        <!-- Stack the columns on mobile by making one full-width and the other half-width -->
        <v-row>
          <div class="d-flex align-center">
            <v-img
              class="shrink mr-0"
              contain
              src="./assets/logo-v.png"
              transition="scale-transition"
              width="40"
            />
            <div
              style="float:left; width: 2px;height: 75%; background: darkgray;"
            ></div>
            <v-img
              class="shrink mt-1"
              contain
              src="./assets/logo-f.png"
              width="100"
            />
          </div>
          <v-spacer></v-spacer>
          <v-btn
            x-large
            text
            :class="localeClass.appbarClass"
            @click="$route.name !== 'Ocrhome' && $router.push('/')"
            class="plain-btn appbar-btn"
          >
            {{ $t("appBar.ocrhome") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            x-large
            text
            :class="localeClass.appbarClass"
            @click="$route.name !== 'Scenario' && $router.push('/scenario')"
            class="plain-btn appbar-btn"
          >
            {{ $t("appBar.scenario") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            x-large
            text
            :class="localeClass.appbarClass"
            @click="$route.name !== 'Ocrsource' && $router.push('/ocrsource')"
            class="plain-btn appbar-btn"
          >
            {{ $t("appBar.ocrsource") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            x-large
            text
            :class="localeClass.appbarClass"
            @click="$route.name !== 'Tools' && $router.push('/tools')"
            class="plain-btn appbar-btn"
          >
            {{ $t("appBar.tools") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-menu open-on-hover bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-translate</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="setLanguage('zh-hans')"
                >简体中文</v-list-item
              >
              <v-list-item @click="setLanguage('en')">English</v-list-item>
            </v-list>
          </v-menu>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <!--  右侧停靠 dockBar  -->
    <div class="onlineContactBox">
      <ul>
        <li class="onlineContent" style="list-style-type:none;">
          <router-link to="/contact">{{ $t("dockBar.contact") }}</router-link>
        </li>
        <li class="onlineTel">
          <p>
            {{ $t("dockBar.contactMe") }}
            <span>15013818605</span>
          </p>
          <a href="javascript:;">{{ $t("dockBar.Telephone") }}</a>
        </li>
        <li class="onlineEmail">
          <p>
            {{ $t("dockBar.sendMail") }}
            <span>services@zhideli.com.cn</span>
          </p>
          <a href="javascript:;">{{ $t("dockBar.mail") }}</a>
        </li>
      </ul>
    </div>

    <!--  底部栏 footerBar  -->
    <v-footer padless>
      <v-container>
        <v-row>
          <v-col cols="4"></v-col>
          <v-col cols="4">
            <div
              style="font-size:20px;"
              class="pt-6 grey--text text--darken-3 text-left"
            >
              {{ $t("footerBar.links") }}
            </div>
          </v-col>
          <v-col cols="4">
            <div
              style="font-size:20px;"
              class="pt-6 grey--text text--darken-3 text-left"
            >
              {{ $t("footerBar.contactUs") }}
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4"></v-col>
          <v-col cols="4">
            <a
              href="https://ai.baidu.com/tech/ocr"
              style="text-decoration:none;"
              class="py-2 text-body-1 grey--text text-left"
              target="_blank"
            >
              {{ $t("footerBar.lnc1") }}
            </a>
            <br>
            <a
              href="https://www.xfyun.cn/services/common-ocr"
              style="text-decoration:none;"
              class="py-2 text-body-1 grey--text text-left"
              target="_blank"
            >
              {{ $t("footerBar.lnc2") }}
            </a>
            <br>
            <a
              href="https://www.intsig.com/public/ai_new/card.shtml"
              style="text-decoration:none;"
              class="py-2 text-body-1 grey--text text-left"
              target="_blank"
            >
              {{ $t("footerBar.lnc3") }}
            </a>
            <br>
            <a
              href="https://www.4paradigm.com/product/hypercycle_ocr.html"
              style="text-decoration:none;"
              class="py-2 text-body-1 grey--text text-left"
              target="_blank"
            >
              {{ $t("footerBar.lnc4") }}
            </a>
          </v-col>
          <v-col cols="4">
            <div class="text-body-1 grey--text text-left">
              {{ $t("footerBar.tel") }}
            </div>
            <div class="text-body-1 grey--text text-left">
              {{ $t("footerBar.mail") }}
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4"></v-col>
          <v-col cols="4"></v-col>
          <v-col cols="4">
            <v-btn v-for="icon in icons" :key="icon" class="mx-4" icon>
              <v-icon size="30px">
                {{ icon }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-card-text class="py-2 white grey--text text--darken-3 text-center">
        {{ $t("footerBar.copyright") }} 
        <a style="text-decoration:none;" class="py-2 white grey--text text--darken-3" href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2021128587号</a>  
        <a style="text-decoration:none;" class="py-2 white grey--text text--darken-3" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030702004270" target="_blank"> 粤公网安备 44030702004270号 </a>
      </v-card-text>
    </v-footer>
    <BackTop :defaultProps="55" :date="1000"></BackTop>
  </v-app>
</template>

<script>
import BackTop from "./components/backTop.vue";

export default {
  name: "App",
  components: {
    BackTop,
  },
  data: () => ({
    icons: ["mdi-wechat", "mdi-linkedin"],
  }),

  created() {
    this.$i18n.locale = "zh-hans";
  },

  methods: {
    setLanguage(lang) {
      switch (lang) {
        case "zh-hant":
          this.$i18n.locale = lang;
          break;
        case "en":
          this.$i18n.locale = lang;
          break;
        default:
          this.$i18n.locale = "zh-hans";
          break;
      }
    },
  },

  watch: {
    "$i18n.locale": {
      handler(newVal) {
        document.title = this.$i18n.t("title");
        switch (newVal) {
          case "en":
            this.localeClass = {
              appbarClass: "plain-btn appbar-btn-en",
            };
            break;
          default:
            this.localeClass = {
              appbarClass: "plain-btn appbar-btn-hans",
            };
            break;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style>
/* 客服留言 s */
.onlineContactBox {
  position: fixed;
  right: 0;
  top: 55%;
  z-index: 99;
}
.onlineContactBox ul li a {
  width: 58px;
  height: 50px;
  display: block;
  color: #fff;
  box-sizing: border-box;
  text-align: center;
  margin-bottom: 1px;
  background: #004fcf no-repeat center 7px;
  padding-top: 28px;
  font-size: 12px;
  text-decoration: none;
  list-style-type: none;
}
.onlineContactBox ul .onlineContent a {
  background: #004fcf url("./assets/onlineContent.png") no-repeat center 7px;
}
.onlineContactBox ul .onlineTel a {
  background: #004fcf url("./assets/onlineTel.png") no-repeat center 7px;
}
.onlineContactBox ul .onlineEmail a {
  background: #004fcf url("./assets/onlineEmail.png") no-repeat center 7px;
}
.onlineContactBox .onlineTel,
.onlineContactBox .onlineEmail {
  display: flex;
  position: relative;
}
.onlineContactBox .onlineTel p,
.onlineContactBox .onlineEmail p {
  height: 50px;
  background: #5d8dec;
  color: #fff;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  padding: 5px 15px 0;
  box-sizing: border-box;
  position: absolute;
  right: 60px;
  display: none;
}
.onlineContactBox .onlineTel p span,
.onlineContactBox .onlineEmail p span {
  display: block;
}
.onlineContactBox .onlineTel:hover a,
.onlineContactBox .onlineEmail:hover a {
  background-color: #548cff;
}
.onlineContactBox .onlineTel:hover p,
.onlineContactBox .onlineEmail:hover p {
  display: block;
}
/* 客服留言 e */

.classtitle {
  width: 100%;
  color: #333;
  text-align: center;
  line-height: 132px;
  font-size: 31px;
  font-weight: normal;
  letter-spacing: 1px;
}
</style>
