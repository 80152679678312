<!--
 * @Author: your name
 * @Date: 2021-07-27 14:37:28
 * @LastEditTime: 2021-09-23 09:53:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \main-site\src\views\Home.vue
-->
<template>
  <div>
    <v-img
      src="../assets/main_banner.png"
      gradient="to right, rgba(1,1,1,.9), rgba(1,1,1,.4), rgba(1,1,1,.0)"
      height="380px"
    >
      <div
        style="position: absolute; top: 25%; left: 15%; height: 80%;"
      >
        <h3
          style="font-size: 28px;color: #fff;line-height: 80px;font-weight: 500;"
        >
          AI文字识别 让您的系统更具竞赛力
        </h3>
        <p style="color: #fff; line-height: 36px;font-size: 16px;">
          多场景、高精度的文字检测与识别服务，识别结果添加到多层PDF文件
          <br>稳定易用的在线API、离线SDK、个性化定制等，让您的系统更具竞赛力
        </p>
        <br>
        <v-btn
          outlined
          color="#f7a300"
          width="158px"
          height="44px"
          style="font-size:20px;"
          @click="$router.push('/contact')" href="javascript:;"
          >我要咨询</v-btn
        >
      </div>
    </v-img>

    <v-container>
      <!-- 第一层 -->
      <v-row no-gutters>
        <v-col>
          <h2 class="classtitle">在线API</h2>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="7">
          <img
            style="width: 100%;height: 390px;"
            src="../assets/home_api.png"
          />
        </v-col>

        <v-col cols="1"> </v-col>
        <v-col>
          <br>
          <br>
          <br>
          <h4 style="font-size: 24px;line-height: 80px;font-weight: 500; ">
            在线API服务
          </h4>
          <p
            style="color:#7A7A7A;line-height: 36px;font-size: 16px;"
          >
            在线应用场景，一次集成，按需使用，无需部署、无需购置算力及硬件设备，支持在线扩容和专属定制服务。
          </p>
          <br>
          <v-btn
            outlined
            color="#f7a300"
            width="158px"
            height="44px"
            style="font-size:20px;"
            @click="$router.push('/contact')" href="javascript:;"
            >API文档</v-btn
          >
        </v-col>
      </v-row>

      <!-- 第二层 -->
      <v-row no-gutters>
        <v-col>
          <h2 class="classtitle">
            离线SDK
          </h2>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <br>
          <br>
          <br>
          <h4 style="font-size: 24px;line-height: 80px;font-weight: 500; ">
            离线SDK方案
          </h4>
          <p
            style="color:#7A7A7A;line-height: 36px;font-size: 16px;"
          >
            离线应用场景，一次投入，永久使用，
            <br>支持算力和硬件设备弹性扩缩容。
          </p>
          <br>
          <v-btn
            outlined
            color="#f7a300"
            width="158px"
            height="44px"
            style="font-size:20px;"
            @click="$router.push('/contact')" href="javascript:;"
            >SDK文档</v-btn
          >
        </v-col>
        <v-col cols="1"> </v-col>
        <v-col cols="7">
          <img
            style="width: 100%;height: 390px;"
            src="../assets/home_sdk.jpg"
          />
        </v-col>
      </v-row>

      <!-- 第三层 -->
      <v-row no-gutters>
        <v-col>
          <h2 class="classtitle">
            定制服务
          </h2>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="7">
          <img
            style="width: 100%;height: 390px;"
            src="../assets/home_service.png"
          />
        </v-col>

        <v-col cols="1"> </v-col>
        <v-col>
          <br>
          <br>
          <br>
          <h4 style="font-size: 24px;line-height: 80px;font-weight: 500; ">
            个性化的图文、表格识别
          </h4>
          <p
            style="color:#7A7A7A;line-height: 36px;font-size: 16px;"
          >
            按应用场景、业务需求定制解决方案。
            <br>特定图文、表格的识别方案，包括数据标注和预处理、模型finetune、模型集成、部署、实施等服务。
          </p>
          <br>
          <v-btn
            outlined
            color="#f7a300"
            width="158px"
            height="44px"
            style="font-size:20px;"
            @click="$router.push('/contact')" href="javascript:;"
            >获取服务</v-btn
          >
        </v-col>
      </v-row>

      <!-- 第四层 -->
      <v-row no-gutters>
        <v-col>
          <h2 class="classtitle">
            资&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;源
          </h2>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="5" align="center" outlined>
          <img
            style="width: 100%;height: 200px;"
            src="../assets/home_modelzoo.jpg"
          />
          <v-btn
            outlined
            color="#f7a300"
            width="158px"
            height="44px"
            style="font-size:20px;"
            @click="$router.push('/ocrsource?resources=opensource')" href="javascript:;"
            >开源OCR方案
          </v-btn>
        </v-col>

        <v-col cols="2"> </v-col>
        <v-col cols="5" align="center">
          <img
            style="width: 100%;height: 200px;"
            src="../assets/home_bigdata.jpg"
          />
          <v-btn
            outlined
            color="#f7a300"
            width="158px"
            height="44px"
            style="font-size:20px;"
            @click="$router.push('/ocrsource?resources=datasets')" href="javascript:;"
            >开放数据集
          </v-btn>
        </v-col>
      </v-row>

      <!-- 第五层 -->
      <v-row no-gutters>
        <v-col>
          <h2 class="classtitle">行业动态</h2>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="3.5">
          <v-card outlined>
            <a
              href="https://www.leiphone.com/category/industrynews/yos4jsFfFgkAqmcY.html"
              style="color:#7A7A7A;font-size: 16px; text-decoration:none;"
              target="_blank"
            >
              <img
                style="width: 100%;height: 150px;"
                src="../assets/home_news4.jpg"
              />
              腾讯云微搭上线OCR文字识别能力，<br>助力抗疫一线高效录入信息
              <br>识别核酸检测记录、行程卡等截图中的信息
            </a>
          </v-card>
        </v-col>

        <v-col cols="1"> </v-col>

        <v-col cols="3.5">
          <v-card outlined>
            <a
              href="https://www.leiphone.com/category/ai/Vy3NQGFBHDVedUaB.html"
              style="color:#7A7A7A;font-size: 16px; text-decoration:none;"
              target="_blank"
            >
              <img
                style="width: 100%;height: 150px;"
                src="../assets/home_news5.jpg"
              />
              不一样的科大讯飞，<br>他们把计算机视觉踢进“世界杯”
              <br>科大讯飞的标签，多了“计算机视觉”
            </a>
          </v-card>
        </v-col>

        <v-col cols="1"> </v-col>
        <v-col cols="3.5">
          <v-card outlined>
            <a
              href="https://www.leiphone.com/category/industrynews/78K8IpMTh98GUO4t.html"
              style="color:#7A7A7A;font-size: 16px; text-decoration:none;"
              target="_blank"
            >
              <img
                style="width: 100%;height: 150px;"
                src="../assets/home_news6.jpg"
              />
              大华AI荣获文本识别算法评测两项第一
              <br>未来，大华股份将进一步聚焦技术研究创新，
              <br>持续提升数智化能力，深耕更广阔的应用场景
            </a>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Ocrhome",
};
</script>
