<!--
 * @Author: your name
 * @Date: 2021-07-27 14:37:28
 * @LastEditTime: 2021-08-20 15:25:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \main-site\src\views\Home.vue
-->
<template>
  <div>
    <v-img
      src="../assets/home_ocrsource.jpg"
      gradient="to right, rgba(1,1,1,.9), rgba(1,1,1,.4), rgba(1,1,1,.0)"
      height="380px"
    >
      <div
        style="position: absolute; top: 25%; left: 15%; width: 50%; height: 80%;"
      >
        <h3
          style="font-size: 28px;color: #fff;line-height: 80px;font-weight: 500;"
        >
          资源中心
        </h3>
        <p style="color: #fff; line-height: 36px;font-size: 16px;">
          OCR开源方案以及开放数据集
          <br />OCR数据标注工具和数据合成工具
        </p>
        <br />
        <v-btn
          outlined
          color="#f7a300"
          width="158px"
          height="44px"
          style="font-size:20px;"
          @click="$router.push('/contact')" href="javascript:;"
          >我要咨询</v-btn
        >
      </div>
    </v-img>

    <br /><br /><br />
    <v-container>
      <!-- 第一层 -->
      <v-row no-gutters>
        <v-col align="center">
          <v-btn-toggle v-model="resources" color="blue accent-3" group>
            <v-btn value="all" style="font-size: 20px;" rounded>
              全部
            </v-btn>
            <v-btn value="opensource" style="font-size: 20px;">
              开源OCR
            </v-btn>
            <v-btn value="datasets" style="font-size: 20px;">
              开放数据集
            </v-btn>
            <v-btn value="annotation" style="font-size: 20px;">
              标注工具
            </v-btn>
            <v-btn value="synthesis" style="font-size: 20px;">
              数据合成工具
            </v-btn>            
          </v-btn-toggle>
        </v-col>
      </v-row>
      <div style="border-bottom: 2px solid #ced0d6;"></div>
      <br />

      <v-row v-if="resources == 'all' || resources == 'opensource'">
        <v-col>
          <v-card outlined>
            <v-row>
              <v-col cols="4">
                <v-img
                  class="grey lighten-1"
                  contain
                  height="250"
                  src="../assets/ocrsource_paddleocr.jpg"
                ></v-img>
              </v-col>
              <v-col cols="8">
                <v-card-title>
                  百度PaddleOCR
                </v-card-title>
                <v-card-subtitle>
                  <div style="height:120px;">
                  PaddleOCR旨在打造一套丰富、领先、且实用的OCR工具库，助力开发者训练出更好的模型，并应用落地。
                  <br>特性：
                  <br>&nbsp;&nbsp;PP-OCR系列高质量预训练模型，准确的识别效果，PP-Structure文档结构化系统
                  <br>&nbsp;&nbsp;丰富易用的OCR相关工具组件，支持用户自定义训练，提供丰富的预测推理部署方案
                  <br>&nbsp;&nbsp;支持PIP快速安装使用啊，可运行于Linux、Windows、MacOS等多种系统
                  </div>
                </v-card-subtitle>
                <v-card-text style="color:#888888;">
                  <div
                    style="border-bottom: 1px solid #ced0d6;vertical-align: bottom;"
                  ></div>
                  <br />
                  PaddleOCR&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;百度&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PaddlePaddle
                  <span style="left:90%;position: absolute;">
                    <a
                      style="text-decoration: none;"
                      target="__blank"
                      href="https://github.com/PaddlePaddle/PaddleOCR"
                      >点击前往</a
                    >
                  </span>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="resources == 'all' || resources == 'opensource'">
        <v-col>
          <v-card outlined>
            <v-row>
              <v-col cols="4">
                <v-img
                  class="grey lighten-1"
                  contain
                  height="250"
                  src="../assets/ocrsource_mmocr.jpg"
                ></v-img>
              </v-col>
              <v-col cols="8">
                <v-card-title>
                  OpenMMLab MMOCR
                </v-card-title>
                <v-card-subtitle>
                  <div style="height:120px;">
                  MMOCR是OpenMMlab新成员，一个专注于文字检测，识别以及下游任务如关键信息提取的工具箱。
                  <br>特点：
                  <br>&nbsp;&nbsp;全流程：支持文字检测、文字识别以及其下游任务，比如关键信息提取等。
                  <br>&nbsp;&nbsp;多模型：我们实现了 10 余种优秀算法。文字检测算法包括单阶段检测算法和双阶段检测算法；文字识别包含规则文字识别和非规则文字识别算法；关键信息提取包含基于图模型的关键信息提取算法。
                  </div>
                </v-card-subtitle>
                <v-card-text style="color:#888888;">
                  <div
                    style="border-bottom: 1px solid #ced0d6;vertical-align: bottom;"
                  ></div>
                  <br />
                  MMOCR&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OpenMMLab&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;pytorch
                  <span style="left:90%;position: absolute;">
                    <a
                      style="text-decoration: none;"
                      target="__blank"
                      href="https://mmocr.readthedocs.io"
                      >点击前往</a
                    >
                  </span>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="resources == 'all' || resources == 'opensource'">
        <v-col>
          <v-card outlined>
            <v-row>
              <v-col cols="4">
                <v-img
                  class="grey lighten-1"
                  contain
                  height="250"
                  src="../assets/ocrsource_tesseractocr.jpg"
                ></v-img>
              </v-col>
              <v-col cols="8">
                <v-card-title>
                  TesseractOCR
                </v-card-title>
                <v-card-subtitle>
                  <div style="height:120px;">
                  Tesseract OCR 该软件包包含一个OCR引擎 -  libtesseract和一个命令行程序 -  tesseract。
                  <br>OCR引擎基于神经网络（LSTM），但仍然支持传统OCR识别，通过使用Legacy OCR Engine模式（--oem 0）启用。
                  <br>Tesseract 支持 unicode（UTF-8），可以“开箱即用” 识别100多种语言。
                  <br>Tesseract支持各种输出格式：纯文本，hOCR（HTML），PDF，不可见文本的PDF，TSV。
                  </div>
                </v-card-subtitle>
                <v-card-text style="color:#888888;">
                  <div
                    style="border-bottom: 1px solid #ced0d6;vertical-align: bottom;"
                  ></div>
                  <br />
                  Tesseract&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Google&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;C/C++
                  <span style="left:90%;position: absolute;">
                    <a
                      style="text-decoration: none;"
                      target="__blank"
                      href="https://tesseract-ocr.github.io"
                      >点击前往</a
                    >
                  </span>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="resources == 'all' || resources == 'datasets'">
        <v-col>
          <v-card outlined>
            <v-row>
              <v-col cols="4">
                <v-img
                  class="grey lighten-1"
                  contain
                  height="250"
                  src="../assets/ocrsource_icdar2019.png"
                ></v-img>
              </v-col>
              <v-col cols="8">
                <v-card-title>
                  ICDAR2019-LSVT数据集
                </v-card-title>
                <v-card-subtitle>
                  <div style="height:120px;">
                  ICDAR2019-LSVT数据集共45w中文街景图像，包含5w（2w测试+3w训练）全标注数据（文本坐标+文本内容），40w弱标注数据（仅文本内容）
                  </div>
                </v-card-subtitle>
                <v-card-text style="color:#888888;">
                  <div
                    style="border-bottom: 1px solid #ced0d6;vertical-align: bottom;"
                  ></div>
                  <br />
                  LSVT&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ICDAR&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;JSON
                  <span style="left:90%;position: absolute;">
                    <a
                      style="text-decoration: none;"
                      target="__blank"
                      href="https://ai.baidu.com/broad/downloadterms?dataset=lsvt"
                      >点击下载</a
                    >
                  </span>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="resources == 'all' || resources == 'datasets'">
        <v-col>
          <v-card outlined>
            <v-row>
              <v-col cols="4">
                <v-img
                  class="grey lighten-1"
                  contain
                  height="250"
                  src="../assets/ocrsource_icdar2017.jpg"
                ></v-img>
              </v-col>
              <v-col cols="8">
                <v-card-title>
                  ICDAR2017-RCTW-17数据集
                </v-card-title>
                <v-card-subtitle>
                  <div style="height:120px;">
                  ICDAR2017-RCTW-17数据集共包含12,000+图像，大部分图片是通过手机摄像头在野外采集的。有些是截图。这些图片展示了各种各样的场景，包括街景、海报、菜单、室内场景和手机应用程序的截图。
                  <br>提取码: 5mzs
                  </div>
                </v-card-subtitle>
                <v-card-text style="color:#888888;">
                  <div
                    style="border-bottom: 1px solid #ced0d6;vertical-align: bottom;"
                  ></div>
                  <br />
                  RCTW&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ICDAR&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TXT
                  <span style="left:90%;position: absolute;">
                    <a
                      style="text-decoration: none;"
                      target="__blank"
                      href="https://pan.baidu.com/s/1b9NMKXk47MGrnmUB91KbhQ"
                      >百度网盘</a
                    >
                  </span>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

            <v-row v-if="resources == 'all' || resources == 'datasets'">
        <v-col>
          <v-card outlined>
            <v-row>
              <v-col cols="4">
                <v-img
                  class="grey lighten-1"
                  contain
                  height="250"
                  src="../assets/ocrsource_ycg09.png"
                ></v-img>
              </v-col>
              <v-col cols="8">
                <v-card-title>
                  中文文档文字识别数据集
                </v-card-title>
                <v-card-subtitle>
                  <div style="height:120px;">
                  数据集共约364万张图片，图片分辨率统一为280x32，按照99:1划分成训练集和验证集。
                  <br>数据利用中文语料库（新闻 + 文言文），通过字体、大小、灰度、模糊、透视、拉伸等变化随机生成
                  <br>包含汉字、英文字母、数字和标点共5990个字符（字符集合：https://github.com/YCG09/chinese_ocr/blob/master/train/char_std_5990.txt）
                  <br>每个样本固定10个字符，字符随机截取自语料库中的句子
                  <br>提取码: lu7m
                  </div>
                </v-card-subtitle>
                <v-card-text style="color:#888888;">
                  <div
                    style="border-bottom: 1px solid #ced0d6;vertical-align: bottom;"
                  ></div>
                  <br />
                  5990&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;YCG09&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TXT
                  <span style="left:90%;position: absolute;">
                    <a
                      style="text-decoration: none;"
                      target="__blank"
                      href="https://pan.baidu.com/s/1QkI7kjah8SPHwOQ40rS1Pw "
                      >百度网盘</a
                    >
                  </span>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="resources == 'all' || resources == 'annotation'">
        <v-col>
          <v-card outlined>
            <v-row>
              <v-col cols="4">
                <v-img
                  class="grey lighten-1"
                  contain
                  height="250"
                  src="../assets/ocrsource_ppocrlabel.jpg"
                ></v-img>
              </v-col>
              <v-col cols="8">
                <v-card-title>
                  半自动标注工具PPOCRLabel
                </v-card-title>
                <v-card-subtitle>
                  <div style="height:120px;">
                  PPOCRLabel是一款适用于OCR领域的半自动化图形标注工具，内置PP-OCR模型对数据自动标注和重新识别。
                  <br>使用Python3和PyQT5编写，支持矩形框标注和四点标注模式，导出格式可直接用于PaddleOCR检测和识别模型的训练。
                  </div>
                </v-card-subtitle>
                <v-card-text style="color:#888888;">
                  <div
                    style="border-bottom: 1px solid #ced0d6;vertical-align: bottom;"
                  ></div>
                  <br />
                  PyQT5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;百度&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PaddleOCR
                  <span style="left:90%;position: absolute;">
                    <a
                      style="text-decoration: none;"
                      target="__blank"
                      href="https://github.com/PaddlePaddle/PaddleOCR/tree/release/2.4/PPOCRLabel"
                      >点击前往</a
                    >
                  </span>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="resources == 'all' || resources == 'annotation'">
        <v-col>
          <v-card outlined>
            <v-row>
              <v-col cols="4">
                <v-img
                  class="grey lighten-1"
                  contain
                  height="250"
                  src="../assets/ocrsource_labelimg.jpg"
                ></v-img>
              </v-col>
              <v-col cols="8">
                <v-card-title>
                  labelImg
                </v-card-title>
                <v-card-subtitle>
                  <div style="height:120px;">
                  LabelImg是一个最常用的图形图像标注工具。
                  <br>使用Python编写，Qt作为其图形界面。
                  <br>标注以 PASCAL VOC 格式保存为 XML 文件，是 ImageNet 使用的格式。
                  </div>
                </v-card-subtitle>
                <v-card-text style="color:#888888;">
                  <div
                    style="border-bottom: 1px solid #ced0d6;vertical-align: bottom;"
                  ></div>
                  <br />
                  QT&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ImageNet&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;XML
                  <span style="left:90%;position: absolute;">
                    <a
                      style="text-decoration: none;"
                      target="__blank"
                      href="https://github.com/tzutalin/labelImg"
                      >点击下载</a
                    >
                  </span>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      
      <v-row v-if="resources == 'all' || resources == 'synthesis'">
        <v-col>
          <v-card outlined>
            <v-row>
              <v-col cols="4">
                <v-img
                  class="grey lighten-1"
                  contain
                  height="250"
                  src="../assets/ocrsource_styletext.jpg"
                ></v-img>
              </v-col>
              <v-col cols="8">
                <v-card-title>
                  数据合成工具Style-Text
                </v-card-title>
                <v-card-subtitle>
                  <div style="height:120px;">
                  Style-Text数据合成工具是基于百度和华科合作研发的文本编辑算法《Editing Text in the Wild》
                  <br>主要框架包括：
                  <br>&nbsp;&nbsp;1.文本前景风格迁移模块 2.背景抽取模块 3.融合模块。经过这样三步，就可以迅速实现图像文本风格迁移。
                  </div>
                </v-card-subtitle>
                <v-card-text style="color:#888888;">
                  <div
                    style="border-bottom: 1px solid #ced0d6;vertical-align: bottom;"
                  ></div>
                  <br />
                  Style transfer&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;百度/华科&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Text
                  <span style="left:90%;position: absolute;">
                    <a
                      style="text-decoration: none;"
                      target="__blank"
                      href="https://gitee.com/paddlepaddle/PaddleOCR/tree/release/2.4/StyleText"
                      >点击下载</a
                    >
                  </span>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <br /><br /><br />
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Ocrsource",
  data() {
    return {
      resources: "all",
    };
  },
  mounted: function() {
    this.resources = this.$route.query.resources;
    if (this.resources === undefined) {
      //等于 undefined
      this.resources = "all";
    }
  },
};
</script>
