<!--
 * @Author: your name
 * @Date: 2021-07-27 14:37:28
 * @LastEditTime: 2021-08-05 16:33:25
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \main-site\src\views\Home.vue
-->
<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="2"></v-col>
        <v-col cols="8">
          <h3 class="formBox_h3 pt-10">
            感谢您对智得立的信赖！
            <p style="font-size: 14px;">
              请填写下面的表格，我们会尽快与您联系~
            </p>
          </h3>
          <p class="text-center pt-6" style="font-size: 14px;">
            智得立严格遵守用户隐私条款，您所填写的信息我们会严格保密，请放心填写~
          </p>
        </v-col>
        <v-col cols="2"></v-col>
      </v-row>
      <v-row>
        <v-col cols="4"></v-col>
        <v-col cols="4">
          <p style="font-size: 14px; color:#fa012a;"
          v-text="errormsg"></p>
          <b style="font-size: 14px; font-weight:normal;"
            >公司名称<i style="color:red;">*</i></b
          >
          <br />
          <input
            class="formBox_input"
            type="text"
            placeholder="请输入完整的公司名称"
            id="companyName"
            name="companyName"
            v-model="formMsg.companyName"
          />
        </v-col>
        <v-col cols="4"></v-col>
      </v-row>
      <v-row>
        <v-col cols="4"></v-col>
        <v-col cols="4">
          <b style="font-size: 14px; font-weight:normal;"
            >姓名<i style="color:red;">*</i></b
          >
          <br />
          <input
            class="formBox_input"
            type="text"
            placeholder="请留下您的姓名"
            id="fullName"
            name="fullName"
            v-model="formMsg.fullName"
          />
        </v-col>
        <v-col cols="4"></v-col>
      </v-row>
      <v-row>
        <v-col cols="4"></v-col>
        <v-col cols="4">
          <b style="font-size: 14px; font-weight:normal;"
            >工作邮箱<i style="color:red;">*</i></b
          >
          <br />
          <input
            class="formBox_input"
            type="text"
            placeholder="请输入正确的工作邮箱"
            id="mailbox"
            name="mailbox"
            v-model="formMsg.mailbox"
          />
        </v-col>
        <v-col cols="4"></v-col>
      </v-row>
      <v-row>
        <v-col cols="4"></v-col>
        <v-col cols="4">
          <b style="font-size: 14px; font-weight:normal;"
            >联系电话<i style="color:red;">*</i></b
          >
          <br />
          <input
            class="formBox_input"
            type="text"
            placeholder="请留下您的联系电话"
            id="telephone"
            name="telephone"
            v-model="formMsg.telephone"
          />
        </v-col>
        <v-col cols="4"></v-col>
      </v-row>
      <v-row>
        <v-col cols="4"></v-col>
        <v-col cols="4">
          <b style="font-size: 14px; font-weight:normal;"
            >服务需求<i style="color:red;">*</i></b
          >
          <br />
          <textarea
            class="formBox_input"
            placeholder="请尽可能详细的描述您的需求、数据规模、以及预算等信息，我们会反馈的更快哦~"
            id="demand"
            name="demand"
            rows="4"
            v-model="formMsg.demand"
          >
          </textarea>
        </v-col>
        <v-col cols="4"></v-col>
      </v-row>
      <v-row>
        <v-col cols="4"></v-col>
        <v-col cols="4" class="pb-10" align="center">
          <v-btn
            outlined
            color="#f7a300"
            width="158px"
            height="44px"
            style="font-size:20px;"
            @click="postMsg()"
            >提 交</v-btn
          >
        </v-col>
        <v-col cols="4"></v-col>
      </v-row>
    </v-container>

    <div class="text-center">
      <v-dialog v-model="showdialog" width="300"
        persistent=true>
        <v-card
          align="center">
          <v-card-text>
            <br>
            <br>
            提交成功！感谢您支持智得立。
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn 
              style="font-size: 16px;" 
              color="#f7a300" 
              text 
              @click="showdialog = false;$router.push('/')" 
              href="javascript:;">
              确 定
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "Contact",
  data() {
    return {
      errormsg:'',
      showdialog: false,
      formMsg:{
        companyName:'',
        fullName:'',
        mailbox:'',
        telephone:'',
        demand:'',
      },
    };
  },
  
  methods: {
    postMsg() {
      // check input 
      if(this.formMsg.companyName.length < 3) {
        this.errormsg = '公司名称最少输入3个字符 '
      } else if(this.formMsg.fullName.length < 1) {
        this.errormsg = '请填写姓名'
      } else if(this.formMsg.mailbox.length < 1 || this.formMsg.mailbox.indexOf('@') < 1|| this.formMsg.mailbox.indexOf('.') < 2) {
        this.errormsg = '请填写正确的工作邮箱'
      } else if(this.formMsg.telephone.length < 10) {
        this.errormsg = '请填写正确的手机号码'
      } else if(this.formMsg.fullName.length < 1) {
        this.errormsg = '需求描述不能为空'
      } else {
        axios
        .post('/api/sendmsg', JSON.stringify(this.formMsg))
        .then((response) =>  { 
          if(response.status != 200) {
            console.log(response);
          }else{
            this.errormsg = ''
            this.showdialog = true;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      }
    },
  },
};
</script>

<style>
.formBox_h3 {
  border-bottom: 1px dashed #d2d2d2;
  font-size: 20px;
  line-height: 26px;
  font-weight: normal;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 9px;
}

.formBox_input {
  width: 100%;
  padding: 15px 10px;
  font-size: 14px;
  line-height: 18px;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
}
</style>
