<!--
 * @Author: your name
 * @Date: 2021-07-29 14:42:23
 * @LastEditTime: 2021-09-23 09:56:42
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \main-site\src\components\backTop.vue
-->


<template>
  <div class="backtop" v-show="visible" @click="backTop" v-bind:style="style">
    <img src="../assets/back_top.png"  width="55" height="55"/>
  </div>
</template>

<script>
export default {
  name: 'backTop',
  data () {
    return {
      'visible': false,
      'ret': 0,
      'obj': null,
      'speed': 0,
      'times': 0,
      'time': 0,
      'style': {
        'position': 'fixed',
        'right': '30px',
        'bottom': '20px',
        'cursor': 'pointer'
      },
      'x': 0,
      'y': 0,
      'translateY': 0,
      'angle': 0
    }
  },
  props: {
    defaultProps: {
      type: Number,
      default: 400
    },
    date: {
      type: Number,
      default: 500
    }
  },
  mounted () {
    window.addEventListener('scroll',this.hanScroll)
  },
  methods: {
    hanScroll(){
      const scrollTop = this.getScroll(window)
      this.visible = scrollTop > this.defaultProps
    },
    getScroll(w){
      this.ret = w.pageYOffset
      const method = 'scrollTop'
      if(typeof this.ret !== 'number'){
        let d= w.document;
        this.ret = d.documentElemelnt[method]
        if(typeof this.ret !== 'number'){
          this.ret = d.body[method]
        }
      }
      return this.ret
    },
    backTop(){
      const initerval = 30
      let num = this.date/initerval
      this.time = 0
      this.times = num;
      this.speed = this.ret / num
      this.obj = setInterval(this.setScroll,initerval)
    },
    setScroll(){
      if(this.time > this.times || this.ret<=0){
        clearInterval(this.obj)
        return
      }
      this.time++
      this.ret -= this.speed
      document.documentElement.scrollTop = document.body.scrollTop = this.ret
    }
  }
}
</script>

