import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

var _hmt = _hmt || [];
window._hmt = _hmt; // 修改为window 全局变量
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?a5979a063b744bdbc247ef3e603e6c1c";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();

new Vue({
  router,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')