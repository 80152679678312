import Vue from 'vue'
import VueRouter from 'vue-router'
/*
import Homepage from '../views/Homepage.vue'
import Solution from '../views/Solution.vue'
import Service from '../views/Service.vue'
import Usercase from '../views/Usercase.vue'
import Resource from '../views/Resource.vue'
*/
import Contact from '../views/Contact.vue'
import Ocrhome from '../views/Ocrhome.vue'
import Scenario from '../views/Scenario.vue'
import Ocrsource from '../views/Ocrsource.vue'
import Tools from '../views/Tools.vue'
import Test from '../views/Test.vue'

Vue.use(VueRouter)

const routes = [
  /*
  {
    path: '/',
    name: 'Homepage',
    component: Homepage
  },
  {
    path: '/solution',
    name: 'Solution',
    component: Solution
  },
  {
    path: '/service',
    name: 'Service',
    component: Service
  },
  {
    path: '/usercase',
    name: 'Usercase',
    component: Usercase
  },
  {
    path: '/resource',
    name: 'Resource',
    component: Resource
  },  
  */
  {
    path: '/',
    name: 'Ocrhome',
    component: Ocrhome
  },
  {
    path: '/scenario',
    name: 'Scenario',
    component: Scenario
  },
  {
    path: '/ocrsource',
    name: 'Ocrsource',
    component: Ocrsource
  },
  {
    path: '/tools',
    name: 'Tools',
    component: Tools
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/test',
    name: 'Test',
    component: Test
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (window._hmt) {
      if (to.path) {
        window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
      }
  }
  next();
});

export default router
