import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import zhHans from 'vuetify/src/locale/zh-Hans.ts';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { zhHans },
        current: "zh-Hans",
      }
});
