<!--
 * @Author: your name
 * @Date: 2021-07-27 14:37:28
 * @LastEditTime: 2021-08-20 15:25:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \main-site\src\views\Home.vue
-->
<template>
  <div>
    <v-img
      src="../assets/home_tools.png"
      gradient="to right, rgba(1,1,1,.9), rgba(1,1,1,.4), rgba(1,1,1,.0)"
      height="380px"
    >
      <div
        style="position: absolute; top: 25%; left: 15%; width: 50%; height: 80%;"
      >
        <h3
          style="font-size: 28px;color: #fff;line-height: 80px;font-weight: 500;"
        >
          常用工具
        </h3>
        <p style="color: #fff; line-height: 36px;font-size: 16px;">
          图片转双层PDF
          <br />词云图生成、随机密码生成等常用运维工具
        </p>
        <br />
        <v-btn
          outlined
          color="#f7a300"
          width="158px"
          height="44px"
          style="font-size:20px;"
          @click="$router.push('/contact')" href="javascript:;"
          >联系我们</v-btn
        >
      </div>
    </v-img>

    <br /><br /><br />
    <v-container>
      <!-- 第一层 -->
      <v-row no-gutters>
        <v-col cols="12">
            <h3 style="font-size: 20px;color: #000;line-height: 80px;text-align:center;">
              图片转双层PDF
            </h3>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="6" >
          <v-card outlined
            style="height:700px;">
            <br>
                <v-row justify="space-around">
                  <v-col cols="6" style="text-align:center;">
                    <span >图片大小不超过3M</span>
                  </v-col>
                  <v-col cols="6">
                    <v-btn rounded color="cyan" dark
                    onclick="javascript:document.getElementById('ocr_pic').click();"
                      >试试你的图片？</v-btn>
                    <input type="file" style="display: none;" name="pic" id="ocr_pic" @change="generate_pdf"/>
                  </v-col>
              </v-row>
            <br>
            <v-row justify="center">

            <div id="wrapper" 
              class="wrapper"
              v-show="wrapperShow"
              ref = "wrapper_ref"
            ></div>
              <img id="detect_img" 
              style="height:612px;
                    width: 94%;
                    img:not([src]){opacity: 0};"
              alt=""
              :src="imgSrc"/>   

            </v-row>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card outlined
          style="height:700px;">
            <br>
                <v-row justify="space-around">
                <v-col cols="6" style="text-align:center;">
                  <span >识别结果为PDF文件</span>
                </v-col>
                <v-col cols="6">
                  <v-btn rounded color="cyan" dark
                    @click="browseFolder"
                  >文件另存至本地</v-btn>
                </v-col>
              </v-row>
              <v-row justify="center">
              <object id="detect_pdf" 
              style="height:612px;
                    width: 94%;"
              alt=""
              :data="pdfSrc" 
              type="application/pdf"
              ref = "pdf_ref"/>   
              </v-row>
          </v-card>
        </v-col>        
      </v-row>

      <br /><br />
      <br /><br />
   
      <!-- 第二层 -- >
      <v-row no-gutters>
        <v-col cols="12">
            <h3 style="font-size: 20px;color: #000;line-height: 80px;text-align:center;">
              词云图生成
            </h3>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="6">
          <v-card outlined>
            <br>
                <v-row justify="space-around">
                <v-col cols="3">
                  <span>文字颜色：</span>
                </v-col>
                <v-col cols="3">
                </v-col>
              </v-row>
            <br>
              <v-textarea
                disabled:true
                height=252px
                outlined
                name="input-7-4"
                label="词条文本（词条名称 词频）"
                v-model.trim="wordCloudData"
              >
              </v-textarea>
            <v-row justify="space-around">
              <v-btn
              outlined
              color="#f7a300"
              width="158px"
              height="44px"
              style="font-size:20px;"
              @click="drawCloud"
              >生成词云图</v-btn
            >
            </v-row>
          <br>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card outlined>
            <br>
                <v-row justify="space-around">
                <v-col cols="12" style="text-align: center;">
                  <span>生成的词云图</span>
                </v-col>
              </v-row>
            <br>            
            <div  style="height:252px;border:solid 1px #A0A0A0;width:100%;overflow-y:auto;">
              <div  style="height:400px;width:100%;"></div>
            </div>
            <v-row justify="space-around">
              <div  style="height:30px;width:100%;overflow-y:auto" id="chart_container" ref="chart_container"></div>
            </v-row>
            <v-row justify="space-around">
              <v-btn
              outlined
              color="#f7a300"
              width="158px"
              height="44px"
              style="font-size:20px;"
              >图片另存</v-btn
              >
            </v-row>
            <br>
          </v-card>
        </v-col>        
      </v-row>

      <br /><br />
      <br /><br />
      -->

  <!-- 第三层 -->
      <v-row no-gutters>
        <v-col cols="12">
            <h3 style="font-size: 20px;color: #000;line-height: 80px;text-align:center;">
              随机密码生成
            </h3>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="6">
          <v-card outlined>
            <br>
            <v-row justify="space-around">
            <v-switch
              v-model="genpwd.lowercase"
              class="ma-4"
              label="小写字母(a..z)"
              required
            ></v-switch>
            <v-switch
              v-model="genpwd.number"
              class="ma-4"
              label="数字(0..9)"
              required
            ></v-switch>
            </v-row>
            <v-row justify="space-around">
            <v-switch
              v-model="genpwd.uppercase"
              class="ma-4"
              label="大写字母(A..Z)"
              required
            ></v-switch>              
            <v-switch
              v-model="genpwd.special"
              class="ma-4"
              label="特殊字符"
              required
            ></v-switch>
            </v-row>
            <v-row justify="space-around">
              <v-col cols="3">
            <v-select
              v-model="genpwd.pwdlen"
              :items="genpwd.pwditems"
              label="密码长度"
            ></v-select>
            </v-col>
              <v-col cols="3">
            <v-select
              v-model="genpwd.countnum"
              :items="genpwd.countnumitems"
              label="密码个数"
            ></v-select>
            </v-col>
            </v-row>
            <v-row justify="space-around">
            <v-btn
            outlined
            color="#f7a300"
            width="158px"
            height="44px"
            style="font-size:20px;"
            @click="generate_pw"
            >生成密码</v-btn
          >
          </v-row>
          <br>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card outlined>
              <v-textarea
                disabled:true
                height=252px
                outlined
                name="input-7-4"
                label="生成的密码"
                v-model.trim="genpwd.passwordtext"
                ref="passwordtext_ref"
              >
                
              </v-textarea>
            <v-row justify="space-around">
              <v-btn
              outlined
              color="#f7a300"
              width="158px"
              height="44px"
              style="font-size:20px;"
              @click="copy"
              >复制至剪贴板</v-btn
            >
          </v-row>
          <br>
          </v-card>
        </v-col>        
      </v-row>

      <br /><br /><br />
    </v-container>
  </div>
</template>

<style>
    img[src=""]{
      opacity: 0;
    }
</style>

<script>
import * as echarts from 'echarts';
import 'echarts-wordcloud';
import axios from 'axios'

export default {
  name: "Tools",
  data() {
    return {
      /* 图片转双层PDF */
      imgSrc: "./tools_sample.jpg",
      pdfSrc: "./tools_sample.pdf",
      wrapperShow: false,
      /* 词云图生成 */
      wordCloudData:[{
                id: 1,
                content:'name'
            }],
      /* 随机密码生成 */
      genpwd: {
				lowercase: true,
				uppercase: true,
        number: true,
				special: true,
        pwditems:[6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32],
        countnumitems:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
				pwdlen: 12,
				countnum: 10,
        passwordtext:' ',
        letter_capital_array: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q',
          'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
        ],
        letter_lowercase_array: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p',
          'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'
        ],
        number_array: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
        special_char_array: ['~', '!', '@', '#', '$', '%', '^', '&', '*', '?', '_', '-'],
      }
    }
  },
  methods: {
    /* 图片转双层PDF */
    generate_pdf() {
      let file = document.getElementById("ocr_pic").files[0];
            // 判断图像格式是否匹配
            let imageName = file.name;
            let index = imageName.lastIndexOf('.');
            let extName = imageName.substr(index + 1);
            let imgArr = ['jpg', 'bmp', 'png', 'jpeg'];
            if (!(imgArr.includes(extName.toLocaleLowerCase()))){
                alert("图像文件格式不支持！");
                return;
            }

            // 判断图像大小是否符合
            let imageSize = file.size / 1024 / 1024;
            if (imageSize >= 3) {
                alert("图像大小超过3M！");
                return;
            }

            let g_this = this;
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function(e){
                var upload_data = {"file": reader.result};
                g_this.imgSrc = reader.result;
                g_this.wrapperShow = true;
                console.log(upload_data)
                axios
                .post('/api/sendocr', JSON.stringify(upload_data))
                .then((response) =>  { 
                  if(response.status != 200) {
                    console.log(response);
                  }else{
                    g_this.wrapperShow = false;
                    g_this.pdfSrc = 'data:application/pdf;base64,'+ response.data['pdf'];
                  }
                })
                .catch(function (error) {
                  console.log(error);
                });
                
            console.log(e)
          }
          //reader.readAsDataURL(this.files[0])
        
    },
    browseFolder() {
      if(this.pdfSrc.length > 10){
        let content = this.pdfSrc.split(';base64,')[1];
        var raw = window.atob(content);
        var rawLength = raw.length;
        var uInt8Array = new Uint8Array(rawLength);
        for(var i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        var blob = new Blob([uInt8Array], {type:'application/'+'pdf'}); 
        //保存文件
        var aLink = document.createElement('a');
        var evt = document.createEvent("HTMLEvents");
        evt.initEvent("click", true, true);
        aLink.download = 'download.pdf';
        aLink.href = URL.createObjectURL(blob);
        aLink.click();
      }else{
        console.log('没有base64代码');
      }
    },
    /* 词云图生成 */
    drawCloud() {
            // let maskImage = new Image(); //可以根据图片形状生成有形状的词云图
            // maskImage.src= this.bgImg;
            let list = this.wordCloudData.map((item) => ({
                name: item.content,
                value: item.id
            }))
            if(list.length == 0){
                list = [{name:'无',value:50}]
            }
            list = [{name:'无',value:50}, {name:'有',value:50}, {name:'好',value:50}]
            let myChart = echarts.init(document.getElementById('chart_container'));
            let option = 
            {
                tooltip: {
                    show: true,
                },
                // backgroundColor:'#fff', // 画布背景色
                series: [
                {
                    name: "热词",
                    type: "wordCloud",
                    // maskImage: maskImage, // 图片形状
                    keepAspect: false,
                    sizeRange: [10, 40], //画布范围，如果设置太大会出现少词（溢出屏幕）
                    rotationRange: [0, 0], //数据翻转范围
                    // shape: "circle",
                    // drawOutOfBound: true, // 超出画布的词汇是否隐藏
                    drawOutOfBound: false,
                    color:"#fff",
                    left: "center",
                    top: "center",
                    right: null,
                    bottom: null,
                    // width: "100%",
                    height: "100%",
                    gridSize: 8,
                    textPadding: 10,
                    autoSize: {
                        enable: true,
                        minSize: 6,
                    },
                    textStyle: {
                        normal: {
                            fontFamily: 'sans-serif',
                            fontWeight: 'bold',
                            color:"#333", // 字体颜色
                            // color: function () { // 字体颜色
                            //     return 'rgb(' + [
                            //         Math.round(Math.random() * 160),
                            //         Math.round(Math.random() * 160),
                            //         Math.round(Math.random() * 160)
                            //     ].join(',') + ')';
                            // },
                        },
                        emphasis: {
                            // focus: 'self',
                            textStyle:{
                                shadowBlur: 10,
                                shadowColor: "#333",
                            }
                        },
                    },
                    data: list,
                },
                ],
            };
            // maskImage.onload = function() {
                myChart.setOption(option, true)
            // };
        },
    /* 随机密码生成 */
		generate_pw() {// 生成密码 这是最重要的方法!!!
			try {
				//初始化数据
				var genpwd_data = this.genpwd;
				
				// 开始生成密码
				//for 生成多个随机密码
				// {生成一个随机密码 并写到追加到密码框 添加换行符号
        var  pass_result = '';
				for (var pwindex = 0; pwindex < genpwd_data.countnum; pwindex++)
					pass_result += (pass_result ? "\n" : "") + this.generate_one_pw();

				//随机密码生成结束
				genpwd_data.passwordtext = pass_result;
				//光标定位到密码结果框
				this.$refs.passwordtext_ref.focus();
			} catch (e) {
				console.log(e.toString());
			}
		},
		generate_one_pw() {
			// 生成开始时间
			var startTime = new Date().getTime();
			var genpwd_data = this.genpwd;
			//(排除字符&&不重复)>必须包含>(字母大写 | 字母小写 | 数字 | 特殊符号 )
			var pw_arr = new Array(genpwd_data.pwdlen);

			//(字母小写 | 字母大写 | 数字 | 特殊符号 )
			var accepted = new Array();
			if (genpwd_data.lowercase)
				accepted.push(0);

			if (genpwd_data.uppercase)
				accepted.push(1);

			if (genpwd_data.number)
				accepted.push(2);

			if (genpwd_data.special)
				accepted.push(3);

			//  生成的字符长度是否足够   不够再空的位置继续随机生成
			while (genpwd_data.pwdlen > pw_arr.join('').length) {
				if (new Date().getTime() - startTime > 1000) {
					throw new Error("生成循环运算时间过长,配置规则错误");
				}
				//随机一种字符 (字母大写 | 字母小写 | 数字 | 特殊符号 | 用户希望)
				var index = accepted[Math.floor(Math.random() * accepted.length)];
				switch (index) {
					case 0:
						//每种类型字符取一个 并插入到密码数组中
						// 字母 小写
						this.char_array_random_insertion(pw_arr, genpwd_data.letter_lowercase_array);
						break;
					case 1:
						// 字母  大写
						this.char_array_random_insertion(pw_arr, genpwd_data.letter_capital_array);
						break;
					case 2:
						// 数字
						this.char_array_random_insertion(pw_arr, genpwd_data.number_array);
						break;
					case 3:
						// 特殊符号
						this.char_array_random_insertion(pw_arr, genpwd_data.special_char_array);
						break;

				}
			}
			return pw_arr.join('');
		},
		element_random(arr_obj) {//从数组中随机获取一个元素
			if (!arr_obj)
				throw new Error("程序问题:element_random(arr_obj)....arr_obj是空的!");
			if (arr_obj.length == 1)
				return arr_obj[0];
			else
				return arr_obj[Math.floor(Math.random() * arr_obj.length)];

		},
		char_array_random_insertion(pw_arr, insert_char_array) {
			var insert_char = this.element_random(insert_char_array);

			var index = this.random_array_null_index(pw_arr);
			pw_arr[index] = insert_char;
		},
		random_array_null_index(arr_obj) { //随机获取数组空位的索引
			if (!arr_obj)
				throw new Error("程序问题:random_array_null_index(arr_obj)....arr_obj是空的!");
			//找出全部空位
			var null_value_index_arr = [];
			for (var i = 0; i < arr_obj.length; i++)
				if (!arr_obj[i])
					null_value_index_arr.push(i);

			//然后随机获取一个空位
			var index = Math.floor(Math.random() * null_value_index_arr.length);
			return null_value_index_arr[index];
		},
		copy() {
			if (this.genpwd.passwordtext.length < 2) {
				alert('警告哦，密码框还是空的');
			} else {
        const myTextarea = document.createElement("textarea");
        document.body.appendChild(myTextarea);
        myTextarea.value = this.genpwd.passwordtext;
        myTextarea.select();
        if (document.execCommand('copy')) {
            document.execCommand('copy');
            alert('成功复制');
        }
        document.body.removeChild(myTextarea); 
			}
		}
  }
};
</script>

<style>
/* wrapper */
#wrapper {
    height:620px;
    position: absolute; left:0px;
    width: 100%;
    background:
    linear-gradient(#1a98ca, #1a98ca),
    linear-gradient(90deg, #ffffff33 1px,transparent 0,transparent 19px),
    linear-gradient( #ffffff33 1px,transparent 0,transparent 19px),
    linear-gradient(transparent, #1a98ca);
    background-size:100% 1.5%, 10% 100%,100% 8%, 100% 100%;
    background-repeat:no-repeat, repeat, repeat, no-repeat;
    background-position: 0% 0%, 0 0, 0 0, 0 0;
    /* 初始位置 */
    clip-path: polygon(0% 0%, 100% 0%, 100% 1.5%, 0% 1.5%);
    /* 添加动画效果 */
    animation: move 1s infinite linear;
}

@keyframes move{
    to{
        background-position: 0 100%,0 0, 0 0, 0 0;
        /* 终止位置 */
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
}
</style>