<!--
 * @Author: your name
 * @Date: 2021-07-27 14:37:28
 * @LastEditTime: 2021-08-20 15:20:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \main-site\src\views\Home.vue
-->
<template>
    <div>
        <h1 align="center">
            RapidOCR Demo &nbsp;
        </h1>
        <div class='area'>
            <span class="uplodNote">图片文件类型支持PNG、JPG、JPEG、BMP，图片大小不超过3M。</span>
            <span class="btn-gen" onclick="document.getElementById('ocr_pic').click();">试试你的图片？</span>
            <input type="file" style="display: none;" name="pic" id="ocr_pic" @change="generate_pdf"/>
        </div>
        <br/>
        <div class="area">
            <div class="leftarea" id="detectDiv">
                <span class="span_title">文本检测结果</span><br/><br/>
                <div class="wrapper" id="wrapper" hidden></div>
                <img id="detect_img" :src="imgSrc"/>
            </div>

            <div class="rightarea" id="recogDiv">
                <span id="rec_res" class="span_title">文本识别结果</span>
                <br/> <br/>
                <table id="locTable" class="table" cellspacing="0" cellpadding="5"></table>
            </div>
        </div>      
    </div>
</template>

<script>
export default {
    name: "Test",
    data(){
        return{
            imgSrc:""
        }
    },
    methods: {
    /* 图片转双层PDF */
    generate_pdf() {
        let file = document.getElementById("ocr_pic").files[0];
        console.log("1")
            // 判断图像格式是否匹配
            let imageName = file.name;
            let index = imageName.lastIndexOf('.');
            let extName = imageName.substr(index + 1);
            let imgArr = ['jpg', 'bmp', 'png', 'jpeg'];
            if (!(imgArr.includes(extName.toLocaleLowerCase()))){
                alert("图像文件格式不支持！");
                return;
            }
console.log("2")
            // 判断图像大小是否符合
            let imageSize = file.size / 1024 / 1024;
            if (imageSize >= 3) {
                alert("图像大小超过3M！");
                return;
            }
console.log("3")
            if (!window.FileReader) return; // 看是否支持FileReader
            let reader = new FileReader();
            reader.readAsDataURL(file); // 关键一步，在这里转换的
            console.log("4")
            let that = this;
            reader.onloadend = function () {
                console.log("5")
                that.imgSrc = this.result;//赋值
            }
            console.log("6")



            /*
            var reader = new FileReader();
            reader.onload = function(e){
                var upload_data = {"file": reader.result};
                this.imgSrc = reader.result;
                console.log("4")
                console.log(upload_data)
                
                $.ajax(
                {
                    url:"/ocr",
                    type:"POST",
                    data: JSON.stringify(upload_data),
                    contentType: 'application/json; charset=UTF-8',
                    dataType: 'json',

                    beforeSend:function(){
                        $("#detect_img").attr('src', reader.result);
                        $("#detect_img").attr("width", "95%");
                        $("#detect_img").attr("height", "100%");

                        $("#wrapper").show();
                        $("#locTable").hide();
                        $("#rec_res").html("文本识别结果");
                    },

                    success:function (data) {
                        $("#wrapper").hide();
                        if (data){
                            if (data['image']){
                                $("#detect_img").attr('src', 'data:image/jpeg;base64,'+ data['image']);
                                $('#detect_img').show();
                            }
                            if (data["elapse"]){
                                document.getElementById("rec_res").textContent = "文本识别结果(耗时:" + String(data['elapse']) + "s)"
                                $("#rec_res").show();
                            }
                            if (data["rec_res"]){
                                var rec_res = JSON.parse(data["rec_res"]);

                                // 绘制整个结果表格
                                var data = "";
                                data += "<tr>" +
                                            '<th bgcolor="#C0C0C0">序号</th>' +
                                            '<th bgcolor="#C0C0C0">识别结果</th>' +
                                            '<th bgcolor="#C0C0C0">置信度</th>' +
                                        "</tr>"
                                for (let i = 0; i < rec_res.length; i++) {
                                    const element = rec_res[i];
                                    let num = element[0];

                                    let rec_result = element[1];

                                    let score = Number(element[2]);
                                    score = score.toFixed(4);

                                    data += "<tr>"
                                    data += "<td>" + num + "</td>";
                                    data += "<td align='left'>" + rec_result + "</td>";
                                    data += "<td>" + score + "</td>";
                                    data += "</tr>"
                                }
                                document.getElementById("locTable").innerHTML = data;
                                $("#locTable").show();
                            }
                        }

                    }
                }
            );
            console.log(e)
          }*/
          //reader.readAsDataURL(this.files[0])
        
        }
    }
};
</script>

<style>
body{
    background-color:#ebedef;
}
.btn-gen {
    /* position: absolute;top:540px; left: 500px; */
    background-color: #00a1d6;
    text-align: center;
    border-radius: 18px;
    margin-top: 5px;
    font-size: 15px;
    padding: 5px 5px;
    height: 20px;
    width: 120px;
    text-transform: uppercase;
    color: #fff;
    border:none;
}
.btn-gen:hover,
.btn-gen:focus {
    border-color: #23AAEE;
    background-color: #23AAEE;
    color: white;
    cursor: pointer;
}
.area{
    text-align: center;
    height: auto;
    margin: auto;
}
.leftarea{
    float: left;
    width: 50%;
    height: auto;
    position: relative;
}

.rightarea{
    float: left;
    width: 50%;
    height: auto;
}
.table{
    width: auto;
    height: auto;
    margin: 0 auto;

}
.span_title{
    width: 98%;
    height: 36px;
    margin-top: 4px;
    line-height: 32px;
    background-color: #00a1d6;
    border: 1px solid #00a1d6;
    border-radius: 20px;
    color: #fff;
    display: inline-block;
    text-align: center;
    font-size: 26px;
    transition: .3s;
    box-sizing: border-box;
}
.uplodNote{
    font-size: 14px;
    color:#000;
    font-style: italic;
}
a{
    text-decoration:none;
}

/* wrapper */
.leftarea>#wrapper {
    position: absolute; top: 36px; left:0px;
    width: 98%;
    height: 100%;
    background:
    linear-gradient(#1a98ca, #1a98ca),
    linear-gradient(90deg, #ffffff33 1px,transparent 0,transparent 19px),
    linear-gradient( #ffffff33 1px,transparent 0,transparent 19px),
    linear-gradient(transparent, #1a98ca);
    background-size:100% 1.5%, 10% 100%,100% 8%, 100% 100%;
    background-repeat:no-repeat, repeat, repeat, no-repeat;
    background-position: 0% 0%, 0 0, 0 0, 0 0;
    /* 初始位置 */
    clip-path: polygon(0% 0%, 100% 0%, 100% 1.5%, 0% 1.5%);
    /* 添加动画效果 */
    animation: move 1s infinite linear;
}

@keyframes move{
    to{
        background-position: 0 100%,0 0, 0 0, 0 0;
        /* 终止位置 */
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
}
</style>